.details-main{
    height: 100%;
    width: 100vw;

}
.details-main-wrap{
    display: flex;
    width: 100%;
    padding: 1%;justify-content: center;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 5%;
}
.details-main-wrap-image{
    height: 450px;
    width:40%;
    /* background-color: pink; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.details-main-wrap-smalldetils{
    height:20%;
    width:60%;
    padding: 2%;
    /* background-color: red; */
}
.details-main-title{
    font-size: 22px;
    font-weight: 700;
}
.details-main-price{
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    gap: 1%;
}
.main-price-offerprice{
    color: #ff6a00;
    font-size: 20px;
}
.main-price-baseprice{
    text-decoration: line-through;
    font-size: 18px;
}
.main-price-discount{
    color: green;
    font-size: 18px;

}
.details-main-detailsword{
    width: 100%;
    margin-top: 1%;margin-bottom: 1%;
}
.main-detailsword-content{
    font-size: 14px;
    color: #9C9C9C;
    font-weight: 500;
}

.details-main-bajarangi{
    display: flex;
    flex-direction: column;
    gap: 3%;
    margin-top: 0;
    margin-bottom: 20px;
}
.details-main-content{
    display: flex;
    gap: 1%;
}
.main-content-brand{
    font-weight: 600;
    color: black;
}
.main-content-industry{
    color: #9C9C9C;
    font-weight: 500;
}

.details-main-quotes{
    width: 300px;
    background-color: #ff6a00;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 40px;
    border-radius: 5px;
    transition: all ease-in-out 0.3s;
}
.details-main-quotes:hover{
    width: 300px;
    background-color: white;
    color: #ff6a00;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ff6a00;
}
.main-quotes-content{
    font-size:18px;
    font-weight: 600;
}
.details-main-wrap-details{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
}

.details-main-descriptionescription{
    border: 1px solid #DDDDDD;
    padding: 2.2%;
    width:90%;
    border-radius: 5px;
    margin-top: 2%;
}
.main-description-heading{
    font-size: 22px;
    color: #ff6a00;
    font-weight: 600;
}
.main-description-product{
    font-size: 14px;
    color: #9C9C9C;
    font-weight: 500;
    margin-bottom: 2%;
}

.description-product-para-spec{
    display:flex;
    flex-direction: column;
}

p{
    margin: 0;
}

.details-main-buttons{
    display: flex;
    gap: 2%;
}

.details-main-reviews{
    display: flex;
    /* justify-content: center; */
    align-items: center     ;
    gap: 1%;
}

@media (max-width:768px) {
    .details-main-wrap{
        display: flex;
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
    }
    .details-main-wrap-smalldetils {
        height: 20%;
        width: 100%;
        padding: 2%;
        /* background-color: red; */
    }
    .details-main-wrap-image{
        width: 90%;
    }
}