#feature {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* flex-wrap: wrap; */
    padding-top: 50px;
    /* gap: 2%; */
    width: 100%;
    align-self: center;
}

#feature .fe-box {
    width: 15.66667%;
    height: 30%;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    background-color: #e6f7f6;
    justify-content: center;
    align-items: center;
    padding-top: 2%;
    padding-bottom: 2%;
    transition: all ease .5s;
    cursor: pointer;
}

#feature .fe-box:hover {
    box-shadow: 10px 10px 54px rgba(70, 62, 221, 0.1);
    background-color:#ff6a00 ;
    border-radius: 5px;
    /* color: white; */
}
#feature .fe-box:hover h6{
    color: white;
}

#feature .fe-box img {
    width: 100px;
    margin-bottom: 10px;
}

/* #feature .fe-box h6 {
    display: inline-block;
    padding: 9px 8px 6px 8px;
    line-height: 1;
    border-radius: 4px;
    color: #088178;
    background-color: #fddde4;
} */

/* #feature .fe-box:nth-child(2) h6 {
    background-color: #cdebbc;
} */

/* #feature .fe-box:nth-child(3) h6 {
    background-color: #95d5c2;
} */

/* #feature .fe-box:nth-child(4) h6 {
    background-color: #93d7ed;
} */

/* #feature .fe-box:nth-child(5) h6 {
    background-color: #f19ee9;
} */

/* #feature .fe-box:nth-child(6) h6 {
    background-color: #95e3c4;
} */

@media screen and (max-width: 769px) {

    #feature {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        padding-top: 20px;
        flex-direction: column;
    }

    #feature .fe-box {
        width: 90%;
        text-align: center;
        padding: 25px 15px;
        box-shadow: 20px 20px 20px 34px rgba(0, 0, 0, 0.03);
        border: 1px solid #cce7d0;
        border-radius: 4px;
        margin: 15px 10px;

    }
}