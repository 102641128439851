a{
  text-decoration: none;
}

.category-slide {
    width: 22%; /* Use full width on smaller screens */
    max-width: 400px; /* Limit max width for larger screens */
    /* margin: 2% auto;  */
    height: 500px; /* Set a fixed height */
    overflow-y: auto; /* Enable vertical scrolling */
    border: 1px solid #e7e7e7; /* Optional: Add a border for visual separation */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for depth */
  }
  .category-slide::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  .category-slide {
    scrollbar-width: none; /* Hide scrollbar in Firefox */
  }
  
  .category-slide h2 {
    text-align: center; /* Center the header text */
    padding: 10px 0; /* Add some padding */
  }
  
  .category-slide__item {
    padding: 0 14px;
  }
  
  .category-slide__item span {
    display: block;
    border-bottom: 1px solid #e7e7e7;
    align-items: center;
    padding: 10px; /* Increased padding for better touch targets */
    color: #3b3b3b;
    font-size: 15px;
    font-weight: 600;
    transition: all 150ms ease-in-out;
  }
  
  .category-slide__item span:hover {
    cursor: pointer;
    color: #ff6a00;
    text-decoration: none;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .category-slide {
      height: 250px; /* Adjust height for smaller screens */
      display: none;
    }
  }
  
  @media (max-width: 480px) {
    .category-slide {
      height: 200px; /* Further adjust height for mobile */
    }
  
    .category-slide__item span {
      font-size: 14px; /* Decrease font size for mobile */
    }
  }
  