.product-list {
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
    padding: 20px;
    white-space: nowrap; /* Prevent wrapping of items */
    max-height: 600px; /* Set a max height for the scroll area */
    scrollbar-width: none;
  }
  .product-list::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  
  .product-card {
    flex: 0 0 auto; /* Prevent flex items from growing or shrinking */
    width: 350px; /* Base width for larger screens */
    height: fit-content; /* Fixed height for cards */
    margin-right: 16px; /* Space between cards */
    display: flex; /* Use flexbox for internal layout */
    flex-direction: column; /* Align children vertically */
    /* justify-content: space-between; */
  }
  

  .main-wrapper-inner{
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }

  @media (max-width:768px) {
    .main-wrapper-inner{
      padding-left: 0%;
      padding-right: 0%;
    }
  }