@import url('https://fonts.googleapis.com/css2?family=Philosopher&family=Smooch+Sans:wght@100&display=swap');


.card3 {
    width: 350px;
    height: auto;
    background: #fff;
    color: #073b4c;
    font-weight: 600;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: left;
    position: relative;
    transition: all .6s ease;
    /* overflow: hidden; */
    border: 2px solid black;
    border-radius: 5px;
  }
  
  /* .card3::before {
    content: "32,99$";
    position: absolute;
    width: 75px;
    height: 75px;
    top: 35%;
    right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #ef476f;
    color: aliceblue;
    font-weight: 800;
    transition: all .5s ease;
  } */
  
  .card3__image {
    width: initial;
    height: 300px;
    /* background: rgb(6,214,160); */
    /* background: linear-gradient(163deg, #06d6a0 18%, rgba(17,138,178,1) 79%); */
    transition: all .5s ease;
  }
  
  .title3 {
    font-weight: 800;
    font-size: 1.3em;
    display: flex;
    justify-content: center;
    word-wrap: break-word;
    align-items: center;
    font-family: 'Smooch Sans', sans-serif;
  }
  
  .card3__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    gap: 10px;
    /* word-wrap: break-word; */
  }
  
  .card3:hover {
    border-top-left-radius: 35px;
    box-shadow: 5px 5px 0px 0px #06d6a0, 10px 10px 0px 0px #118ab2, 15px 15px 0px 0px #06d6a0, 5px 5px 15px 5px rgba(0,0,0,0);
  }
  
  .card3:hover .card3__image {
    border-top-left-radius: 25px;
  }
  
  .card3:hover::before {
    transform: scale(1.3) rotate(-30deg);
  }


  .justify-content-center{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .proall{
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
  }

  /* .row justify-content-center .col-md-4 col-sm-6 col-xs-8 col-12 mb-4{
    display: flex;
    flex-direction: row;
    background: white;
  } */

  /* style={{background:'white', display:'flex', flexDirection:'row',marginRight:'50px'}} */

  @media screen and (max-width:1025px) {
 .justify-content-center{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }

  .proall{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .col-md-4 col-sm-6 col-xs-8 col-12 mb-4{
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    background: white;
  }

  }





  .product-cards{
    display: flex;
    border: 1px solid #DDDDDD;
    padding:5px;
    flex-direction: column;
    border-radius: 5px;
    width: 300px;
    height: fit-content;
    cursor: pointer;
  }
  .product-cards-content{
    display: flex;
    flex-direction: column;
    padding: 2%;
  }
  .cards-content-price{
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
  .cards-content-offerprice{
    color: #ff6a00;
  }
  .cards-content-baseprice{
    color: black;
    text-decoration: line-through;
  }
  .product-cards-buynow{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff6a00;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    height: 30px;
    font-weight: 700;
  }

  .cards-content-title{
    font-size: 14px;
    /* text-align: justify; */
  }
    
    .product-cards:hover{
      border-color: #ff6a00;
    }


    .product-cards:hover .cards-content-title{
      color: #ff6a00;
    }

    .categories-subtab{
      display: flex;
      border: 1px solid #DDDDDD;
      height: 500px;
      width: 400px;
      flex-direction: column;
      overflow-y: scroll;
      /* justify-content: center; */
      /* align-items: center; */
      border-radius: 0px;
      scrollbar-width: none;
    }

    .categories-subtab-tab{
      padding: 5%;
      display: flex;
      /* justify-content: center; */
      align-items: center;
      border-bottom: 1px solid #DDDDDD;
      cursor: pointer;
      width: 100%;
    }
    .categories-subtab-tab span{
      color: #000;
      font-weight: 700;
      margin-left: 2%;
    }
    .right-arrow{
      color: #000;
    }
    .categories-subtab-tab:hover span {
      color: #ff6a00;
    }
    .categories-subtab-tab:hover .right-arrow {
      color: #ff6a00;
    }

    .categories-subtab-tab:active span{
      color: #ff6a00;
    }

    .categories-subtab-tab:active .right-arrow {
      color: #ff6a00;
    }

    
    .all-card-wrap{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 30px;
    }
    
    .responsive-categories-subtab{
      height: 100%;
      width: 100%;
      display: none;
    }
    .responsive-categories-subtab-button{
     position: fixed;
     bottom: 30px;
     right: 30px;
     height: 50px;
     width: fit-content;
     background-color: #ff6a00;
     display: none;
     justify-content: center;
     align-items: center;
     padding: 2%;
     border-radius: 5px;
    }
    .responsive-categories-subtab-button span{
      color: white;
      font-weight: 600;
    }


    @media (max-width:768px) {
      .categories-subtab{
        display: none;
      }
      .all-card-wrap{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 30px;
      }
      .responsive-categories-subtab{
        height: 100%;
        width: 100%;
        display: flex;
        /* position: absolute; */
      }
      .responsive-categories-subtab-button{
        position: fixed;
        bottom: 30px;
        right: 30px;
        height: 50px;
        width: fit-content;
        background-color: #ff6a00;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2%;
        border-radius: 5px;
       }
      
    }
