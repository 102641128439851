.best_c{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
}

.card15 {
    width: 300px;
    height: 200px;
    perspective: 1000px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
  
  .card15-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.999s;
  }
  
  .card15:hover .card15-inner {
    transform: rotateY(180deg);
  }
  
  .card15-front,
  .card15-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .card15-front {
    /* background-color: #6A2C70; */
    color: #fff;
    display: flex;
    align-items: center;
    /* border: 10px solid #6A2C70; */
    border-radius: 10px;
    justify-content: center;
    font-size: 24px;
    transform: rotateY(0deg);
    
  }
  
  .card15-back {
    background-color: #F08A5D;
    color: #fff;
    display: flex;
    align-items: center;
    border: 10px solid #F08A5D;
    border-radius: 10px;
    justify-content: center;
    font-size: 24px;
    transform: rotateY(180deg);
    word-wrap: break-word;
  }
  