.carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden;
    display: none;
    border-radius: 10px;
}
.carousel-root{
  width: 75%;
}
.imageStyle {
    width: 95vw;
    height: 70vh;
    border-radius: 20px;
  }

  @media (max-width: 768px) {
    .imageStyle {
      width: 90vw;
      height: 40vh;
      border-radius: 15px;
    }
    .carousel-root{
      width: 100%;
    }
  }