.product-card {
  border: 1px solid #ddd;
  /* border-radius: 8px; */
  padding: 16px;
  background-color: #fff;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space out the content */
  width: 350px; /* Fixed width */
  height: 400px; /* Fixed height */
  margin-right: 16px; /* Space between cards */
  transition: all ease .5s;
  cursor: pointer;
}

.product-card:hover{
  border-color: #ff6a00;
}
.product-card:hover .product-details{
  border-color: #ff6a00;
}


.product-details {
  flex: 1; /* Allows it to take available space */
  border-top: 1px solid dimgray;
  margin-top: 5%;
  /* background-color: antiquewhite; */
  margin: 3%;
  transition: all ease .5s;
}

.product-title {
  font-size: 16px;
  margin: 8px 0;
  color: black;
  margin-bottom: 0;
}

.product-price {
  font-size: 18px;
  color: #888;
}

.buy-now-button {
  background-color:#ff6a00;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 2.5%;
}


 .buy-now-button-link{
  display: flex;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.1s;
  position: relative; /* For the animation effect */
  width: 100%;
  justify-content: center;
  align-items: center;
}

.buy-now-button-link:active {
  transform: scale(0.95); /* Slightly shrink the button on press */
}

.buy-now-button-link:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  /* transform: translate(0%, -50%) scale(0); */
  transition: transform 0.5s, opacity 0.5s;
  opacity: 0;
}

.buy-now-button-link:active:after {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  transition: transform 0.5s, opacity 0s; /* Delay opacity transition */
}

.product-description {
  margin-top: 10px;
  font-size: 14px;
}
